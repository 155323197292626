$color-1: black
$color-2: #dd51b1
$color-black: #2b2d36
$color-white: #ffffff

@import url('https://use.typekit.net/zmc8vly.css')

%font-regular
  font-family: degular-display, sans-serif
  font-weight: 400
  font-style: normal
  letter-spacing: .1ch

%font-title
  font-weight: 500
  line-height: .75

%top
  top: 0
  left: 0
  width: 100%

%fixed
  @extend %top

  position: fixed

%absolute
  @extend %top

  position: absolute

*
  margin: 0
  padding: 0
  box-sizing: border-box

body
  @extend %font-regular

  width: 100%
  height: 100vh
  overscroll-behavior: none
  color: $color-black
  background-color: $color-white
  line-height: 1.2

  &::-webkit-scrollbar
    display: none

h1,
h2
  @extend %font-title

canvas.webgl
  @extend %fixed

  pointer-events: none
  mix-blend-mode: screen

  @media (max-width: 64em)
    opacity: .7

.section
  display: block
  justify-content: space-evenly
  align-items: flex-start
  // width: 100vw
  min-height: 100vh
  padding: .5%
  background: $color-white

  @media (max-width: 64em)
    flex-direction: column
    justify-content: center
    min-height: 100vh
    padding: 10vh 5rem

    &:first-of-type
      gap: 0

  &:not(:first-of-type)
    .section__title
      padding-bottom: calc(min(9vw, 6rem))

  &:nth-child(even)
    //flex-direction: row-reverse
    background: $color-black
    color: $color-white

    .section__title
      text-align: right
      width: min-content

    @media (max-width: 64em)
      flex-direction: column

      .section__paragraph
        align-self: flex-start

  &__title
    text-align: left
    text-transform: uppercase
    opacity: .9

    @media (max-width: 64em)
      width: 100%

    &-number
      font-size: min(36vw, 24rem)

    &-text
      font-size: min(10vw, 6rem)
      padding-left: .1ch

    &-arrow
      font-size: min(9vw, 6rem)

      color: $color-black
      margin-top: 1ch
      line-height: 1

      span
        display: inline-block
        transform: rotate(90deg)

  &__paragraph
    align-self: flex-end
    max-width: 20ch
    font-size: 1.4rem

    @media (max-width: 64em)
      font-size: 1.2rem

  &__button
    @extend %font-regular

    display: inline-block
    margin: 5ch 0
    padding: 2ch 5ch
    border: 2px solid currentColor
    border-radius: 50%
    font-size: .8em
    text-transform: uppercase
    letter-spacing: .1ch
    transition: background-color 1s ease
    cursor: pointer

    &:hover,
    &:focus
      border: 2px solid $color-2

.layout
  &__line
    @extend %fixed

    height: 5px
    width: 100%
    z-index: 999
    background-color: $color-black
    transform: scaleX(0)
    mix-blend-mode: difference

  &__faboolea
    position: fixed
    bottom: 3rem
    height: .5rem
    width: 100%
    text-align: center

// 
//
// :root
//   --scrollbar-size: 0px
//
//
// *, :after, :before
//   box-sizing: border-box
//
//
// body
//
//
//
// .viewport
//   overflow: hidden
//   position: fixed
//   height: 100%
//   width: 100%
//   top: 0
//   left: 0
//   right: 0
//   bottom: 0
//
//
// body
//   overflow-x: hidden
//   overflow-y: scroll
//   padding: 0
//   margin: 0
//
//   *:not(.change_color_page)
//     // color: $color-white
//     transition: color 0.5s ease-in-out, border-color 0.5s ease
//     border-color: $color-white
//
//   &.white
//     *:not(.change_color_page)
//       // color: $color-black
//       transition: color 0.5s ease-in-out, border-color 0.5s ease
//       border-color: $color-black
//
//
//
//
// .change_color_page
//   position: fixed
//   display: block
//   top: 0
//   left: 0
//   width: 100vw
//   height: 100vh
//   background-color: $color-black
//   transition: background-color 0.5s ease
//   backface-visibility: hidden
//   transform-style: preserve-3d
//   &.white
//     background-color: $color-white
//
//
//
// .header
//   position: fixed
//   top: 0
//   left: 0
//   width: 100%
//   height: 50px
//   display: flex
//   align-items: center
//   justify-content: center
//   transition: all 0.5s ease-in-out
//   background-color: $color-white
//   &.white
//     background-color: $color-black
//
//
//
// .scrollbar-test
//   position: absolute
//   visibility: hidden
//   overflow: scroll
//   width: 100px
//   height: 100px
//   top: -99999px
//   left: -99999px
//   pointer-events: none
//   user-select: none
//
//
// .fixed-content
//   position: absolute
//   display: block
//   top: 0
//   left: 0
//   right: var(--scrollbar-size, 0px)
//   bottom: 0
//   z-index: 2
//   pointer-events: none
//
//
// .scroll-container
//   position: absolute
//   overflow: hidden
//   z-index: 10
//   backface-visibility: hidden
//   transform-style: preserve-3d
//   width: 100%
//
//
// .content
//   overflow: hidden
//   position: relative
//   width: 100%
//
//
// .spacer
//   background: transparent
//
//
// .single-item
//   flex: 0 0 100vw
//   height: 100vh
//   display: flex
//   align-items: center
//   justify-content: center
//   font-size: 70px
//   &.left
//     justify-content: flex-start
//
//   p
//     width: 300px
//     height: 300px
//     display: flex
//     align-items: center
//     justify-content: center
//     border-width: 2px
//     border-style: solid
//
//
//
// .horizontal-scroll-wrapper
//   position: relative
//
//
// .horizontal-scroll
//   display: flex
//
//
// .horizontal-scroll .single-item
//   flex: 0 0 100vw
//   p
//     width: 600px
//     height: 600px
//     max-width: 80%
//     max-height: 80%
