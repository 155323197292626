.blog__container {
    display: flex;
    justify-content: center;
    height: auto;
    padding-top: 14%;
    flex-wrap: wrap;
}

.blog__container h2 {
    width: 100%;
    text-align: center;
    font-size: 3em;
    margin-bottom: 2em;
}

.block_slide {
    width: 100vw;
    height: 100vh;
    position: absolute;
    background-color: black;
}

.blog-single h1 {
    width: 100%;
    text-align: center;
    font-size: 3em;
    margin-bottom: 2em;
}

.blog-single {
    max-width: 50%;
    margin: auto;
    padding-top: 20vh;
    padding-bottom: 20vh;
}

.blog-single h3 {
    font-size: 2em;
}

.blog-single p {
    margin-top: 1em;
    margin-bottom: 1em;
    font-size: 1.5em;
}

pre {
    border: 1px solid #eee;
    border-radius: .5rem;
    margin: 1%;
    padding: 1%;
}

.blog-single .image {
    width: 100%;
}

@media screen and (max-width:53em) {
    .blog-single {
        max-width: 90%;
        padding-top: 25vh;
    }

    .blog__container {
        padding-top: 62%;
    }
}

.article h3 {
    text-transform: capitalize;
}